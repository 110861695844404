<template>
    <div class="min-h-screen flex flex-col">
        <MenuApp></MenuApp>

        <Home-pop-up></Home-pop-up>

        <div class="mb-auto grow bg-grey200 w-full grow-custom">
            <slot></slot>
        </div>

        <ChatFloating></ChatFloating>
    </div>
</template>

<script>
import HomePopUp from "~/pages/components/HomePopUp.vue";

export default {
    name: "DefaultLayoutWithoutFooter",
    components: {HomePopUp}
}
</script>

<style scoped lang="scss">
.grow-custom {
    @apply flex;

    & > :deep(div){
        @apply w-full;
    }
}
</style>

